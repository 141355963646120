.supportLogin {
  background-color: var(--darkWhite);
  padding: 50px 10vw;
  display: flex;
  justify-content: center;
}
.loginDiv {
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: block;
  width: 100%;
  max-width: 480px;
}
.loginDiv h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}
.signupNavigation {
  margin-bottom: 50px !important;
  text-align: center !important;
  width: 100% !important;
  font-size: 0.875em !important;
  color: var(--footerText) !important;
}
.input {
  height: 50px;
}
.input:hover {
  border-color: var(--purple) !important;
}
.button {
  height: 50px !important;
  width: 100% !important;
  background-color: var(--purple);
  color: var(--white);
  font-size: 20px;
  cursor: pointer;
}
.button:hover {
  background-color: var(--purple) !important;
  color: var(--white) !important;
  opacity: 0.9;
}
.backButton{
  width: 100%;
  text-align: center;
  background: transparent;
  color: var(--footerText);
  outline: none;
  border: none;
  box-shadow: none
}
.backButton:hover{
  background: transparent !important;
  color: var(--footerText) !important;
}
.emailNotReceived button{
  background-color: transparent;
  border: none;
  color: var(--purple);
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  margin-bottom: 20px;
}
.successDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -25px;
  margin-bottom: 25px;
}
.successIcon{
  color: var(--purple);
  font-size: 50px;
}
@media only screen and (max-width: 768px) {
  .supportLogin {
    padding: 40px 5vw;
  }
}
@media only screen and (max-width: 575px) {
  .steps {
    display: none !important;
  }
}
