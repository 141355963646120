.cookiePreferencesButton {
  position: fixed;
  height: 40px;
  width: 40px;
  bottom: 20px;
  left: 20px;
  display: none;
  z-index: 900 !important;
  background-color: var(--white);
  border-radius: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  color: var(--purple) !important;
  cursor: pointer;
}
.cookiePreferencesButton:hover{
  background-color: rgba(0,0,0,.06);
  transition: background-color .2s;
  z-index: 900 !important;
}
.image{
  height: 30px;
  width: 30px;
}
@media only screen and (max-width: 768px) {
  .cookiePreferencesButton {
    height: 30px;
    width: 30px;
  }
  .image{
    height: 20px;
    width: 20px;
  }
}
