.adminDashboard {
  width: 100%;
  flex: 1;
  background-color: var(--greyishWhite) !important;
}
.loginDiv {
  background-color: var(--white) !important;
  width: calc(100% - 40px);
  margin: 20px;
  padding: 20px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--black);
  gap:10px;
}
.clearFilterButton {
  align-self: flex-end;
}
@media only screen and (max-width: 768px) {
  .loginDiv {
    padding: 40px 20px;
  }
}
