.supportLogin {
  background-color: var(--darkWhite);
  padding: 50px 10vw;
  display: flex;
  justify-content: center;
}
.loginDiv {
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 480px;
}
.loginDiv h1{
    font-weight: 600;
}
.imageContainer{
    display: flex;
    justify-content: center;
    width: 80px;
    margin-bottom: 10px;
}
.imageContainer img{
    width: 100%;
}
.mailContainer p:first-of-type{
    margin-bottom: 0 !important;
}
.button{
    margin-top: 10px;
    height: 50px !important;
    width: 100% !important;
    background-color: var(--purple);
    color: var(--white);
    font-size: 20px;
    cursor: pointer;
}
.button:hover{
    background-color: var(--purple) !important;
    opacity: 0.9;
}

@media only screen and (max-width:768px){
    .supportLogin{
        padding: 40px 5vw;
    }
}