.adminDashboard {
  width: 100%;
  flex: 1;
  background-color: var(--greyishWhite) !important;
}
.loginDiv {
  background-color: var(--white) !important;
  width: calc(100% - 40px);
  margin: 20px;
  padding: 40px 80px;
}
.label {
  padding-bottom: 100px !important;
  font-weight: 400 !important;
}
.input {
  height: 50px !important;
  width: 100% !important;
  background-color: #f9f8fa !important;
  border: 0.6px solid #cacbdf !important;
  border-radius: 4px;
}
.input:hover,
.inputTextArea:hover {
  border-color: var(--purple) !important;
}
.inputTextArea {
  width: 100% !important;
  background-color: #f9f8fa !important;
  border: 0.6px solid #cacbdf !important;
  border-radius: 4px;
}
.button {
  margin-top: 20px;
  height: 50px !important;
  width: 100% !important;
  background-color: var(--purple);
  color: var(--white);
  font-size: 20px;
}
.button:hover {
  background-color: var(--purple) !important;
  opacity: 0.9;
}
.traitContainer {
  display: flex;
  flex-direction: column;
}
.titleContainer {
  display: flex;
  gap: 20px;
}
.formItemContainer {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  gap: 20px;
}
.deleteButton {
  background-color: transparent;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
.deleteButton:hover {
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 768px) {
  .createNft {
    width: 100%;
    padding: 40px 5vw;
  }
  .loginDiv {
    padding: 40px 20px;
  }
  .formItemContainer {
    gap: 10px;
  }
}
