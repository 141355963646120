.supportLogin {
  background-color: var(--darkWhite);
  padding: 50px 10vw;
  display: flex;
  justify-content: center;
}
.loginDiv {
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: block;
  width: 100%;
  max-width: 480px;
}
.loginDiv h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}
.signupNavigation {
  margin-bottom: 40px;
  width: 100%;
  font-size: 0.875em !important;
  color: var(--footerText);
}
.signUpLink {
  color: var(--purple);
  text-decoration: underline !important;
}
.input {
  height: 50px;
}
.input:hover {
  border-color: var(--purple) !important;
}
.countryCode {
  width: 20% !important;
  min-width: 70px !important;
}
.mobileField {
  width: calc(100% - max(20%, 80px)) !important;
}
.button {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  background-color: var(--purple);
  color: var(--white);
  font-size: 20px;
  cursor: pointer;
}
.button:hover {
  background-color: var(--purple) !important;
  opacity: 0.9;
}
.loginDiv p {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  text-align: center;
  width: 70%;
}
.link {
  color: var(--purple);
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .supportLogin {
    padding: 40px 5vw;
  }
  .loginDiv p {
    width: 90%;
  }
}
