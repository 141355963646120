.navbar {
  background-color: var(--white) !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: var(--navbarHeight);
  padding: 15px 10vw;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.logo {
  height: 40px;
  width: auto;
}
.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.linksContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.link {
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.1px;
  font-weight: 550;
  color: var(--purple);
}
.link:hover {
  opacity: 0.8;
}
.dropdownButton {
  background-color: transparent;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
}
.avatar {
  background-color: transparent !important;
  color: var(--purple) !important;
  cursor: pointer;
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar:hover {
  opacity: 0.85;
  transition: opacity 0.2s;
}
.avatarItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  align-items: center !important;
  gap: 20px;
  font-weight: 600;
  font-size: 16px;
  width: 200px !important;
}
.avatarItemIcon {
  font-size: 18px;
}
.avatarItemButton {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.button {
  background: transparent !important;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .navbar {
    height: var(--navbarHeight);
    padding: 15px 5vw;
  }
  .logo {
    height: 40px;
    width: auto;
  }
  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }
  .linksContainer {
    display: none;
  }
  .hide {
    display: none;
  }
}
@media only screen and (max-width: 300px) {
  .hideLast {
    display: none;
  }
}
