.adminPanelHeader{
    margin-left: 5px !important;
    width: calc(100% - 5px);
    background-color: var(--white);
    box-sizing: border-box;
    margin: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    position: relative;
    padding: 16px 24px;
    padding-top: 12px;
}
.adminPanelHeader h1{
    width: 100%;
    margin-top: 8px;
    margin-right: 12px;
    margin-bottom: 0;
    color: rgba(0,0,0,.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.adminPanelHeader p{
    width: 100%;
    padding-top: 12px;
}