.cookieBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  align-content: center !important;
  transition: transform 1s ease-in-out;
  padding: 20px 10vw;
  gap: 30px;
  z-index: 10000;
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
.cookieBarText {
  font-size: 16px;
  color: var(--lightBlack);
  margin: 0 !important;
  padding: 0 !important;
}
.cookieBarContainer {
  display: flex;
  justify-content: space-between;
  height: 100% !important;
  align-items: center !important;
  gap: 10px;
}
.link {
  color: var(--linkColor);
  font-style: italic;
}


@media only screen and (max-width: 768px) {
  .cookieBar {
    padding: 20px 5vw;
    gap: 10px;
    flex-direction: column;
    text-align: center;
  }
  .cookieBarContainer {
    display: flex;
    justify-content: space-between;
    height: 100% !important;
    width: 100% !important;
    align-items: center !important;
    gap: 10px;
  }
}
