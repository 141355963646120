.adminLayout {
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  /* display: inline-block; */
  /* position: relative; */
}
.sidebarMenu {
  width: 100%;
  padding-top: 20px;
  min-height: calc(100vh - var(--navbarHeight) - 40px) !important;
  max-height: calc(100vh - var(--navbarHeight) - 40px) !important;
}
.collapseButton {
  height: 40px;
  width: 100%;
  border-radius: 0 !important;
  text-align: right;
  font-size: 1.125em;
}
.mobileTopBar {
  display: none;
  height: 40px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.collapseButton:hover {
  border-color: #d9d9d9 !important;
  color: var(--purple) !important;
}
.mobileTopBar .collapseButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  color: var(--purple) !important;
}
.childContainer {
  width: 100%;
  background-color: var(--greyishWhite) !important;
}
@media only screen and (min-width: 769px) {
  .adminLayoutSider {
    position: sticky !important;
    top: var(--navbarHeight) !important;
  }
}
@media only screen and (max-width: 768px) {
  .adminLayout {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .siderHidden {
    display: none;
    z-index: 500 !important;
  }
  .sider {
    position: absolute; /* Set the sidebarMenu to an absolute position */
    left: 0; /* Position the sidebarMenu on the left side */
    top: 40px; /* Position the sidebarMenu at the top */
    display: flex;
    z-index: 600 !important;
  }
  .childContainer {
    z-index: 1; /* Set a lower z-index for the childContainer */
    position: relative; /* Set the childContainer to a relative position */
    width: 100%;
  }
  .mobileTopBar {
    display: block;
  }
  .collapseButtonHidden {
    display: none !important;
  }
  .sidebarMenu {
    padding-top: 10px;
    padding-right: 10px;
  }
}
