.modalText {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    color: var(--lightBlack);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .modalCookiesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .modalCookieTypeContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .modalCookieTypeContainerOne {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 30%;
  }
  .modalCookieTypeContainerTwo {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    color: var(--lightBlack);
    width: 70%;
  }
  .link{
    color: #5e5eaf;
    font-style: italic;
    font-size: 0.880rem;
  }